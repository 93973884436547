/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { Container } from '@sprinklr/shared-lib';
import { ProductTabs } from '@sprinklr/shared-lib/components/productIconTab';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { ContainerPadding } from '@sprinklr/shared-lib/@types/types';

const AIFeatureTemplate: React.FC<{
  padding: ContainerPadding;
  aiFeatures: any;
}> = ({ padding, aiFeatures }) => {
  const [pt, pb] = useContainerPadding(padding);

  return (
    <Container containerSx={{ pt, pb }}>
      <ProductTabs features={aiFeatures} />
    </Container>
  );
};

export default AIFeatureTemplate;
