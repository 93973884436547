/** @jsx jsx */
import loadable from '@loadable/component';
import { useMemo } from 'react';

import { jsx, Themed, ThemeProvider, Box } from 'theme-ui';
import Card from '../Card';
import { ProductFeatureCardProps } from '../types';
import RichText from '../../richText';
import PRODUCT_ICON_MAP from '../productCard/productIcons';
import { IconTombstone } from '@sprinklr/shared-lib/components/IconTombstone';

const LoadableProduceIcon = loadable(() => import('@sprinklr/shared-lib/assets/svgs/produce.svg'));
const LoadableAnalizeIcon = loadable(() => import('@sprinklr/shared-lib/assets/svgs/analyze.svg'));

const FEATURE_ICON_MAP = {
  // marketing
  PLAN: loadable(() => import('@sprinklr/shared-lib/assets/svgs/plan.svg')),
  PRODUCE: LoadableProduceIcon,
  PUBLISH: loadable(() => import('@sprinklr/shared-lib/assets/svgs/publish.svg')),
  ANALYZE: LoadableAnalizeIcon,

  // ads
  EXECUTE: LoadableProduceIcon,
  OPTIMIZE: loadable(() => import('@sprinklr/shared-lib/assets/svgs/optimize.svg')),

  // research
  LISTEN: loadable(() => import('@sprinklr/shared-lib/assets/svgs/listen.svg')),
  LEARN: loadable(() => import('@sprinklr/shared-lib/assets/svgs/learn.svg')),
  ACT: LoadableProduceIcon,

  // care
  ROUTE: loadable(() => import('@sprinklr/shared-lib/assets/svgs/route.svg')),
  RESOLVE: loadable(() => import('@sprinklr/shared-lib/assets/svgs/resolve.svg')),
  MEASURE: LoadableAnalizeIcon,

  // engagement
  TRIAGE: loadable(() => import('@sprinklr/shared-lib/assets/svgs/triage.svg')),
  ENGAGE: loadable(() => import('@sprinklr/shared-lib/assets/svgs/engage.svg')),
};
type ProductFeatureCardProps = {
  featureDescription: string;
  featureIcon: any;
  featureName: string;
  productShortName: string;
};

const ProductFeatureCard: React.FC<ProductFeatureCardProps> = ({
  featureDescription,
  featureIcon,
  featureName,
  productShortName,
}: ProductFeatureCardProps) => {
  const Icon = FEATURE_ICON_MAP[featureIcon] || PRODUCT_ICON_MAP[productShortName];

  const iconStyles = useMemo(
    () => ({
      height: '30px',
      width: '30px',
      color: productShortName,
      marginBottom: '16px',
      flexShrink: 0,
      fill: 'currentColor',
    }),
    [productShortName]
  );

  return (
    <Card
      cardSx={{
        width: ['100%', '100%', '100%'],
        borderTop: '7px solid',
        borderColor: productShortName,
        justifyContent: 'flex-start',
        overflow: 'hidden',
      }}
    >
      <Icon sx={iconStyles} fallback={<IconTombstone sx={iconStyles} />} />
      <Themed.h4 sx={{ mb: ['20px', '20px', '20px'] }}>{featureName}</Themed.h4>
      <ThemeProvider
        theme={{
          styles: {
            p: {
              fontSize: ['16px', '16px', '16px'],
              mb: [4, 4, 4],
              lineHeight: ['1.6', '1.6', '1.6'],
            },
          },
        }}
      >
        <Box css={{ strong: { fontWeight: 'regular' } }}>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            <RichText richTextObject={featureDescription} />
          </pre>
        </Box>
      </ThemeProvider>
    </Card>
  );
};

ProductFeatureCard.displayName = 'ProductFeatureCard';

export default ProductFeatureCard;
