/** @jsx jsx */
import { Box, Flex, Grid, jsx, Themed } from 'theme-ui';
import ProductIconTab from './ProductIconTab';
import { useState } from 'react';
import ProductFeatureCard from '../card/productFeatureCard';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ProductTabs = ({ features }) => {
  const featuresByProduct = features.reduce((acc, { product, description, icon, name }) => {
    acc[product.shortName] = acc[product.shortName] || {
      name: product.name,
      shortName: product.shortName,
      features: [],
    };
    acc[product.shortName].features.push({
      featureDescription: description,
      featureIcon: icon,
      featureName: name,
      productShortName: product.shortName,
    });
    return acc;
  }, {});

  const [activeIndex, setActiveIndex] = useState(0);
  const productNames = Object.keys(featuresByProduct);
  const activeProduct = featuresByProduct[productNames[activeIndex]];
  const threeColumnsOrFour = activeProduct.features.length % 3 ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr';
  const twoColumsOrThree = activeProduct.features.length % 3 ? '1fr 1fr' : '1fr 1fr 1fr';
  const { t } = useTranslation();

  return (
    <Box>
      <Flex mb={[2, 3, 3]} sx={{ justifyContent: 'center' }}>
        <Themed.h3
          sx={{
            justifyContent: ['center', 'center', 'center'],
            display: ['flex', 'flex', 'flex'],
          }}
        >
          {t('AI in')}
          <Box as="span" sx={{ color: activeProduct.shortName, ml: 2 }}>
            {t(activeProduct.name)}
          </Box>
        </Themed.h3>
      </Flex>
      <Box>
        <Flex sx={{ justifyContent: 'space-around', alignItems: 'center' }}>
          {productNames.map((productName, index) => (
            <Box key={index}>
              <ProductIconTab
                productShortName={productName}
                active={index === activeIndex}
                onClick={() => setActiveIndex(index)}
              />
            </Box>
          ))}
        </Flex>
        <Grid
          sx={{
            mt: 4,
            gridTemplateColumns: ['1', '1', twoColumsOrThree, threeColumnsOrFour],
            columnGap: 1,
            rowGap: 3,
          }}
        >
          {activeProduct.features.map((feature, index) => (
            <Flex
              key={index}
              sx={{
                mx: [0, 2, 2],
                mb: [4, 0, 0],
                flex: ['auto', '1 1 0', '1 1 0'],
              }}
            >
              <ProductFeatureCard {...feature} />
            </Flex>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

ProductTabs.displayName = 'ProductTabs';

export default ProductTabs;
