/** @jsx jsx */
import { useMemo } from 'react';
import { Box, Grid, jsx } from 'theme-ui';
import PRODUCT_ICON_MAP from '../card/productCard/productIcons';
import { Product } from '../../@types/entities';
import { IconTombstone } from '@sprinklr/shared-lib/components/IconTombstone';

type Props = {
  productShortName: Product['shortName'];
  active?: boolean;
  onClick: () => void;
};

const activeSize = ['70px', '106px', '106px'];
const inactiveSize = ['50px', '75px', '75px'];
const ProductIconTab = ({ productShortName, active, onClick }: Props) => {
  const Icon = PRODUCT_ICON_MAP[productShortName];
  const size = active ? activeSize : inactiveSize;

  const iconStyles = useMemo(
    () => ({
      height: '45%',
      width: '45%',
      color: productShortName,
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }),
    [productShortName]
  );

  return (
    <Box onClick={onClick}>
      <Box
        sx={{
          height: size,
          width: size,
          position: 'relative',
          transition: 'height 0.3s, width 0.3s',
          borderRadius: '50%',
          border: active ? '2px solid #1c6cfd' : 'none',
          boxShadow: active ? '0px 2px 10px 0px rgba(0,0,0,0.2)' : 'none',
          '&:hover': {
            height: activeSize,
            width: activeSize,
            border: '2px solid #1c6cfd',
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            bg: productShortName,
            opacity: 0.2,
            borderRadius: '50%',
          }}
        />
        <Icon sx={iconStyles} fallback={<IconTombstone sx={iconStyles} />} />
      </Box>
    </Box>
  );
};

ProductIconTab.displayName = 'ProductIconTab';

export default ProductIconTab;
